import {
  fromPairs,
  startCase,
  get,
  compact,
  map,
  isInteger,
  mapKeys,
  nth,
  includes,
  initial,
  some,
  every,
  pick,
  sortBy,
  zip,
  isEqual,
  lowerCase,
  omit,
  last,
  mapValues,
  upperFirst,
} from 'lodash'
import { compose } from 'lodash/fp'
import { isEqual as isDateEqual } from 'lib/date-fns'
import pluralize from 'pluralize'

import { camelCaseKey, snakeCaseKey } from 'lib/keyCase'
import mapPath from 'lib/mapPath'
import { deepMapValues, deepMapValuesBreadthFirst } from 'lib/deepMap'
import webpackRequireContext from 'lib/webpackRequireContext'

const isTestEnvironment = process.env.NODE_ENV === 'test'

// All entity types in Flex's domain
//
// Standard actions and reducers will be created for all these entity types,
// making it possible to fetch, create or update these entities.
//
// For more info, see ./api, ./endpoints, src/reducers and
// src/redux-crud/entityReducer
export const entityTypes = [
  'addressTimezone',
  'agency',
  'agencyAllocation',
  'agencyAccount',
  'agencyBranch',
  'agencyAccountBranch',
  'agencyAccountVenue',
  'agencyAccountPermission',
  'agencyAccountDetail',
  'agencyAccountResendInvitation',
  'agencyAllocationWorker',
  'agencyNotification',
  'agencyClient',
  'agencyCostCentreCode',
  'agencyJob',
  'agencyJobDetail',
  'agencyJobOverview',
  'agencyWorkerBooking',
  'agencyListing',
  'agencyListingJobCard',
  'agencyVenuesAndRole',
  'agencyPayRate',
  'agencyProvisionalCost',
  'agencyShift',
  'agencyTimesheet',
  'agencyTimesheetWorker',
  'agencyWorker',
  'agencyTimesheetCount',
  'listingPayRate',
  'agencyShiftWorker',
  'approveListing',
  'area',
  'booking',
  'bulkMessage',
  'bulkOffer',
  'bureauResourcesCompany',
  'cancelBooking',
  'costCenterCode',
  'currentUser',
  'dailyShiftBooking',
  'employer',
  'employerAccount',
  'employerAccountInvitation',
  'employerTimesheet',
  'employerTimesheetEntry',
  'employerTimesheetsApproveAll',
  'employerTimesheetsApproval',
  'employerTimesheetRating',
  'employerTimesheetProvisionalCost',
  'employerShiftRateType',
  'csatSurvey',
  'enabledFeature',
  'image',
  'industry',
  'country',
  'internalWorkerInvitation',
  'job',
  'jobCandidate',
  'jobDurationPreview',
  // See syf2backend/app/models/job_rate.rb
  // An entity without own identifier, identified by venue_id+role_id
  'jobRate',
  'jobId',
  'lightVenue',
  'listing',
  'listingAgency',
  'listingAgencyEmail',
  'listingAgencyShifts',
  'listingApprovalDetail',
  'listingOverview',
  'listingPresetReason',
  'listingPublication',
  'listingPublish',
  'listingRole',
  'listingReason',
  'listingTemplate',
  'message',
  'multipleBooking',
  'nonSyftExperience',
  'noShowReason',
  'notification',
  'offerConfig',
  'overtimeRule',
  'page',
  'paymentSession',
  'permission',
  'preferredAgencyWorker',
  'removeWorkerFromAllocation',
  'rating',
  'referralClaim',
  'report',
  'reportType',
  'role',
  'rota',
  'rotaCopy',
  'rotaPublish',
  'shift',
  'shiftBooking',
  'shiftPattern',
  'schedule',
  'scheduleMonth',
  'insight',
  'scheduleAll',
  'skill',
  // See syf2backend/app/modules/jobs/standard_uniforms.rb
  'standardUniform',
  'suggestion',
  'thread',
  'timesheet',
  'timesheetApprovalStat',
  'timesheetEmail',
  'timesheetEntry',
  'timesheetAgencyEntry',
  'timesheetFile',
  'timesheetProvisionalCost',
  'employerBulkBreak',
  'uniform',
  'user',
  'userChatVerification',
  'venue',
  'venuesWithArea',
  'wagePreview',
  'worker',
  'workerExperience',
  'workerJob',
  'workerName',
  'workerRole',
  'workerSignupInvitation',
  'workerVenue',
  'workerTrustedWorkLocation',
  'workLocation',
  'authLoginType',
  'agencyJobShift',
  'bankHolidayRegion',
  'agencyAgentVenue',
  'employerManager',
  'authAccountSetting',
  'scheduledAgency',
  'bookingStat',
  'totalWorkedHour',
  'totalFilledShift',
  'staffAverageRating',
  'venueAverageRating',
  'totalEstimatedCost',
  'costBreakdown',
  'workerNoShow',
  'repeatStaff',
  'overviewVenue',
  /** MARKER FOR ENTITY-TYPES GENERATOR */
]

// Webpack require context
// Webpack is not present in NODE (test environment, jest)
const emptySchema = { type: 'object', properties: {} }
const requireSchemaForEntityType = webpackRequireContext(
  () => require.context('./', false, /\.js$/),
  name => require(`./${name}`),
  emptySchema,
)

// A list of JSON schemas for entity types in Syft's domain
// Schemas adhere to http://json-schema.org
//
// Common use cases:
// - form generation
// - structural validation of entities (request bodies) sent to the backend
// - validation of client-submitted data
// - #entityLabel, #propertyLabel methods below
//
// @type {[ Object ]}
export const schemas = {
  ...fromPairs(entityTypes.map(type => [type, requireSchemaForEntityType(type)])),
  standardUniform: requireSchemaForEntityType('uniform'),
}

// Returns true if the provided argument is a valid identifier for Syft API entities.
// This can be either a zero-based integer or a string UUID.
//
// @param {*} id A candidate for an entity identifier
// @return {Boolean}
export const isIdentifier = id => isInteger(id) || typeof id === 'string'

// Some entity types don't have a simple `id` identifer in Flex API responses.
// This identifier can be either a differently named property in the responses,
// a combination of multiple properties (local and from the backend) or there may
// be no identifier in the response at all. In that case we provide the identifier
// locally.
//
// Entity identifiers are needed e.g. for local caching (identity map).
//
// The most common uses case of this is relationship entities – see an example
// of `jobRate` below.
//
// @private
const customEntityIds = {
  agencyBranch: ['query'],
  agencyTimesheet: ['workerDisplayName', 'id', 'shift.id'],
  agencyAccountPermission: ['employerId', 'venueId', 'agencyAccountId'],
  addressTimezone: ['city', 'line1', 'postCode'],
  agencyListing: ['agencyListingUuid'],
  agencyJob: ['jobId', 'venue.id', 'area.id', 'roleId'],
  agencyJobDetail: ['id', 'venueId', 'areaId', 'roleId'],
  bureauResourcesCompany: ['companyName', 'companyNumber'],
  dailyShiftBooking: ['workerId', 'day'],
  employerAccountInvitation: ['_update', 'token'],
  jobCandidate: ['id', 'shiftId'],
  // Flex API doesn't expose jobRate `id`. Because a jobRate belongs to one venue and
  // it also belongs to one role the composite primary key for jobRate is roleId+venueId
  jobRate: ['venueId', 'roleId'],
  listingApprovalDetail: ['listingId'],
  offerConfig: ['code'],
  paymentSession: ['paymentData'],
  rating: ['jobId', 'workerId'],
  standardUniform: ['picture.uuid'],
  timesheet: ['listingId'],
  timesheetApprovalStat: ['listingId'],
  timesheetAgencyEntry: ['workerDisplayName', 'id', 'shift.id'],
  timesheetEntry: ['worker.id', 'worker.kind', 'worker.agencyShiftWorkerId', 'shift.id'],
  employerTimesheet: ['worker.id', 'worker.kind', 'worker.agencyShiftWorkerId', 'shift.id'],
  employerTimesheetProvisionalCost: [
    'startDate',
    'endDate',
    'timesheetStatuses',
    'workLocationIds',
    'roleIds',
    'workerKinds',
    'workerNames',
    'hideRatedWorkers',
  ],
  employerTimesheetsApproveAll: [
    'startDate',
    'endDate',
    'timesheetStatuses',
    'workLocationIds',
    'roleIds',
    'workerKinds',
    'workerNames',
    'hideRatedWorkers',
  ],
  uniform: ['picture.uuid'],
  workerJob: ['jobId', 'workerId'],
  workerRole: ['workerId', 'roleId'],
  workerSignupInvitation: ['inviteToken'],
  workerVenue: ['venueId'],
  rota: ['venueId', 'dates.from', 'dates.to'],
  schedule: ['venueId'],
  scheduleMonth: ['date'],
  scheduleAll: ['id', 'venueId', 'areaId', 'roleId', 'startTime', 'endTime'],
  reportType: ['key'],
  agencyJobShift: ['id', 'roleId', 'startTime', 'endTime'],
}

/*
 * Paths to identifier properties for a given entity type
 * @returns {[String]}
 */
export const idProperties = entityType => customEntityIds[entityType] || ['id']

/*
 * Function that receives an entity type and an entity object and returns its identifier
 * value. By default this will be value of the 'id' property, e.g. `3` in `{ id: 3 }`.
 *
 * `idForEntity({ $type: 'jobRate', venueId: 2, roleId: 5 })` ~> `"venueId=2&roleId=5"`
 *
 * @returns {String|Number}
 */
export const idForEntity = (entity, options = {}) => {
  const getIdProperties = get(options, 'idProperties', idProperties)
  const idPaths = getIdProperties(get(entity, '$type'))
  if (idPaths.length === 1) {
    return get(entity, idPaths[0])
  } else {
    // `null` is used so that `[1, undefined]` is stringified
    // into `{"firstId":1,"secondId": null}` instead of `{"firstId":1}`
    const query = fromPairs(idPaths.map(path => [path, get(entity, path, null)]))
    // Previously we checked every query value whether it's a valid identifier or not. This did not support partial compound IDs (when one of the `idPaths` is null). This special case is useful when the null identifier indicates a has-many relationship with all existing entities instead of just one. E.g. given an entity compound identifier [job.id, worker.id], its valuation of [23, null] may indicate that the entity belongs to job 23 and all existing workers. The nature of the compound identifier requires there to be only one such entity (such entity is unique).

    return some(Object.values(query), isIdentifier)
      ? // Replace '.' character so that the identifier can occur inside a valid
        // path (e.g. for lodash.get)
        stringifyIdentifier(query)
      : null
  }
}
export const stringifyIdentifier = query => JSON.stringify(query).replace(/\./g, '&dot;')

/*
 * Parse the compound identifier back into an object of identifier properties
 * @returns {Object}
 */
export const parseIdentifier = (id, entityType) => {
  const idPaths = idProperties(entityType)
  if (idPaths.length === 1) {
    return { [idPaths[0]]: id }
  } else {
    const query = id ? JSON.parse(id.replace('&dot;', '.')) : {}
    // TODO Unflatten
    return query
  }
}

// Returns true if the entity has been persisted on the backend
// TODO This doesn't work for entities for which we don't have an id
// from the backend (e.g. timesheetEntry).
export const isEntityNew = entity => !isIdentifier(idForEntity(entity))

// Warn on missing schemas
const missingSchemaNames = compact(map(schemas, (schema, type) => schema === emptySchema && type))
const shouldWarn = missingSchemaNames.length && process.env.NODE_ENV !== 'production' && !isTestEnvironment
if (shouldWarn) {
  /* eslint-disable no-console */
  console.info(`Missing schemas for: ${missingSchemaNames.join(', ')}`)
  /* eslint-enable */
}

const fieldPropertyOfSchema = (shortPath, schema) => {
  // path ~ ['properties', 'address', 'properties', 'line1']
  const path = [
    'properties',
    ...shortPath
      .join('.')
      .replace(/\./g, '.properties.')
      .replace(/\[[\d]*\]/g, '.items')
      .split('.'),
  ]
  // snakeCasedPath ~ ['properties', 'address', 'properties', 'line_1']
  const snakeCasedPath = path.map(snakeCaseKey)
  // snakeCasedShortPath ~ ['address', 'line1']
  const snakeCasedShortPath = shortPath.map(snakeCaseKey)
  // key ~ 'line1'
  const key = last(path)
  const desc = { ...get(schema, snakeCasedPath) }

  if (get(desc, 'properties')) {
    desc.properties = mapValues(desc.properties, (_property, name) => fieldPropertyOfSchema([name], desc))
  }

  // We'll check if the propert is required both in the root object and in the parent
  // object of the property
  // TODO Review
  const rootRequiredProps = get(schema, ['required'])
  // pathToParentObject ~ ['properties', 'address']
  const pathToParentObject = initial(initial(path))
  const parentRequiredProps = get(schema, [...pathToParentObject, 'required'])
  const required =
    includes(rootRequiredProps, snakeCasedShortPath.join('.')) ||
    includes(parentRequiredProps, last(snakeCasedShortPath))

  return desc && { schema, path, key, desc, required }
}

// Schema for a single property for a given entity type
//
// @param {String} barePath Path to the property, e.g. `'venue.desc'. The first
//   part is the singular entity type, followed by a camelCase path to the property
//   in the JSON schema. (`venue.address.postCode`, `area.accessInstructions`)
// @return {null | { schema, path, key, desc }} where:
//   schema ~ {Object} JSON schema for the entity type
//   path ~ {String} property path without the entityType, e.g. "address.postCode"
//   key ~ {String} Last property name in the path, e.g. "postCode"
//   desc ~ {Object} JSON schema node for the property
//   required ~ {Boolean} True if the property is required
// @example
// ```
// const property = propertyAtPath('venue.desc')
// property.schema //=> full JSON schema for `venue`
// property.path //=> 'venue.desc'
// property.key  //=> 'desc'
// property.desc //=> { type: 'string', description: 'Description' }
// property.required //=> false
// ```
export const propertyAtPath = prefixedShortPath => {
  const [schemaName, ...shortPath] = prefixedShortPath.split('.')
  const schema = schemas[schemaName]
  return fieldPropertyOfSchema(shortPath, schema)
}

// Human-readable name of the entity.
// E.g. 'O2 Arena' for `{ id: 3, name: 'O2 Arena' }`.
// Usually it's the value of the `name` or `title` property.
//
// @param {Object} schema Full JSON schema for the entity type
// @param {Object} entity Entity data
// @returns {String?} name of the entity
// @example
// ```
// import { schemas, entityLabel } from 'api/schemas'
// const label = entityLabel(schemas.venue, { id: 3, name: 'O2 Arena' })
// formLabel //=> 'O2 Arena'
// ```
export const entityLabel = entity => {
  const schema = schemas[get(entity, '$type')]
  const labelProperty = get(schema, 'meta.labelProperty') || 'name'
  return entity[labelProperty]
}

// User-facing entity type label
// @param {String} entityType as defined above
// @return {String} label
export const entityTypeLabel = entityType => {
  const name = {
    employerAccount: 'staffing manager',
    workerVenue: 'venue',
  }[entityType]
  return name || lowerCase(entityType)
}

// Human-readable property name
//
// @param {Object} property A property description returned
//   from the #propertyAtPath method
// @returns {String}
// @example
// ```
// const property = propertyAtPath('listing.eventDesc')
// const formLabel = propertyLabel(property)
// formLabel //=> 'Event description'
// ```
export const propertyLabel = property => {
  const { desc, key } = property
  return desc.description || compose(upperFirst, lowerCase, startCase)(key)
}

// @param {Object} entity Valid entity
// @param {Function (([entity], path, schemaProperty) -> [entity])} callback
// @param {Boolean} options.breadthFirst
export const mapEachAssociation = (entity, callback, options = {}) => {
  const deepM = options.breadthFirst ? deepMapValuesBreadthFirst : deepMapValues
  return deepM(entity, (value, path) => {
    const propertyPath = `${entity.$type}.${path}`.replace(/\.\d+/, '[]')
    const schemaProperty = propertyAtPath(propertyPath)
    const schemaPropertyDesc = get(schemaProperty, 'desc')
    const isAssociation = !!get(schemaPropertyDesc, '$rel') && get(schemaPropertyDesc, 'type') === 'array'
    return isAssociation ? callback(value, path, schemaProperty) : value
  })
}

// @param {String} type Entity type
// @return {[String]}
export const propertiesForType = type => {
  const schema = schemas[type]
  const schemaProps = mapKeys(schema.properties, (val, key) => camelCaseKey(key))
  return {
    $type: { type: 'string' },
    localId: { type: 'string' },
    ...schemaProps,
    _destroy: { type: 'boolean' },
  }
}

// @param {String} type Entity type
// @return {[String]}
export const propertyNamesForType = type => {
  return Object.keys(propertiesForType(type))
}

// @param {Object} entity Valid entity
// @return {Object} Entity
export const pickSchemaProperties = entity => {
  return pick(entity, propertyNamesForType(entity.$type))
}

// Tests if two entities are equal, including entities in nested associations.
//
// Only schema-defined properties are checked. Properties with date-time format
// (JSON schema) are compared as dates.
//
// @param {Object} first Valid entity
// @param {Object} second Valid entity
// @return {Boolean}
export const isEntityEqual = (first, second) => {
  if (first.$type !== second.$type) return false
  const properties = propertiesForType(first.$type)
  return every(
    map(properties, (propDesc, prop) => {
      if (get(propDesc, '$rel') && get(propDesc, 'type') === 'array') {
        // TODO This will fail for non-trivial identifier properties
        const [firstSorted, secondSorted] = [first[prop], second[prop]].map(x => sortBy(x, ['id', 'localId']))
        const zipped = zip(firstSorted, secondSorted)
        return every(
          zipped,
          ([firstEnt, secondEnt]) => firstEnt && secondEnt && isEntityEqual(firstEnt, secondEnt),
        )
      } else if (get(propDesc, 'format') === 'date-time') {
        // Strict comparison tests for undefined, null values
        return first[prop] === second[prop] || isDateEqual(first[prop], second[prop])
      } else {
        // Deep comparison tests object and array types (that are not entity associations)
        return isEqual(first[prop], second[prop])
      }
    }),
  )
}

// Add links to inverse associations
//
// Example:
// ```
// const job = [{ $type: 'listing', jobs: [{ $type: job }] }]
// const listing = { $type: 'listing', jobs: [job] }
// const listingWithLinks = populateInverse(listing, 'jobs', { inverseKey: 'listing' })
// //=> [{ $type: 'listing', jobs: [{ $type: job, listing: listing }] }]
// ```
// @param {Object|Array} objectOrArray entity or entities
// @param {String} path dot-separated path to associated entities
// @param {String} options.inverseKey inverse association name
export const populateInverse = (objectOrArray, path, options = {}) => {
  const splitPath = path.split('.')
  const inverseKey = options.inverseKey || pluralize.singular(nth(splitPath, -2))
  return mapPath(objectOrArray, path, (entity, index, parent) => {
    return {
      ...entity,
      [inverseKey]: omit(parent, last(splitPath)),
    }
  })
}
